import React from 'react'
import { graphql } from 'gatsby'


import Text from "../content/texts/Text"

import Layout from '../components/layout'
import SEO from '../components/seo'

export default function LegalPage(props) {
  return (
    <Layout>
        <SEO 
        title={`An independent creative network`} 
        description={`Here's where we post our latest and greatest work from all our offices worldwide. Come see what we've been up to.`} 
        bodyClass={`is-legal`}
        />
        <section className="static_page legal_page container-fluid">
        <header className="row contact_page__header mb-3">
                {props.data.uhcpolicy && (
                <h1 className="col-12 lg">{ props.data.uhcpolicy.description }</h1>
                )}
            </header>
            <div className="row">
              {props.data.uhcpolicy && (
                <aside className="contact_page__intro_text col-12 col-md-10 col-lg-7">
                  <Text text={props.data.uhcpolicy} container={false} breakTextAnimation={false} />
                </aside>
              )}
            </div>
            <header className="row contact_page__header mb-2 pt-4">
                <h1 className="col-12 lg">{ props.data.legalStuff.description }</h1>
            </header>
            <div className="row">
              {props.data.legalStuff && (
                <aside className="contact_page__intro_text col-12 col-md-10 col-lg-7">
                  <Text text={props.data.legalStuff} container={false} breakTextAnimation={false} />
                </aside>
              )}
            </div>
        </section>
    </Layout>
  )
}
export const query = graphql`
  query LegalQuery {
    uhcpolicy: contentfulTexts(slug: { eq: "uhc-policy" }) {
      ...textFragment
    }
    legalStuff: contentfulTexts(slug: { eq: "legal-stuff" }) {
      ...textFragment
    }
  }
`